
// INCLUDE MODULES
window.app = window.app || {};
window.app.maps = window.app.maps || {};
var google = google || {};

// MODULE MAPS
window.app.maps = (function(app, maps, google) {
	var is_cr;
	var current_marker;
	maps.init = function(markers) {
		if(typeof google !== "undefined" && typeof google.maps !== "undefined") {
			var $map = $(".js-map");
			if($map.length) {

				var map, mapOptions, infobox;
				var center_latlng, zoom;

				if($map.data("type") != "cr") {
					center_latlng = new google.maps.LatLng(49.7437989, 15.3386383);
					zoom = 6.6;
				} else if($map.data("type") == "cr") {
					is_cr = true;
					center_latlng = new google.maps.LatLng(49.954815, 15.456510);
					zoom = 7;
					if(app.is_mobile) {
						zoom = 6;
					}
				}

				mapOptions = {
					center: center_latlng,
					zoom: zoom,
					scrollwheel: false,
					styles: [
						{
							stylers: [
								{"saturation": -100}
							]
						},
						{
							featureType: "poi",
							stylers: [
								{visibility: "off"}
							]
						}
					]
				};

				if(app.is_mobile) mapOptions.draggable = false;

				map = new google.maps.Map($map[0], mapOptions);

				if(markers) {
					var image = '/img/icons_map_marker.png';
					for(i in markers) {
						var marker_data = markers[i];
						var marker = new google.maps.Marker({
							position: new google.maps.LatLng(marker_data.lat, marker_data.lng),
							map: map,
							icon: image
						});

						infobox = new InfoBox({
							boxClass: "info-window",
							pixelOffset: new google.maps.Size(-20, -55, "px", "px"),
							alignBottom: true
						});
                        //
						// infobox = new google.maps.InfoWindow({
						// 	class: 'info-window'
						// });

						(function(marker_data) { // SET EVENT HANDLERS USING MARKER DATA
							var marker_data_clsr = marker_data;

							if(is_cr) {
								// marker.addListener('click', function() {
								// 	window.location = marker_data_clsr.link;
								// });

								if(app.is_desktop) {
									marker.addListener('click', function () {
										if (this.position == current_marker && infobox.getVisible()) {
											current_marker = 0;
											infobox.close();
											return true;
										}
										infobox.close();
										var pos = new google.maps.LatLng(this.position.lat(), this.position.lng());

										var $content = $(".info-windows .info-window");
										$content.find("img").attr("src", marker_data_clsr.img_src);
										$content.find("h4").text(marker_data_clsr.name);
										$content.find("a").attr('href',marker_data_clsr.link);

										var _this = this;
										var preload = new Image();
										preload.src = marker_data_clsr.img_src;
										var fn = function() {
											infobox.setContent($content.html());
											infobox.setPosition(pos);
											infobox.open(map, _this);
										};
										preload.onload = fn;
										preload.onerror = fn;
										current_marker = this.position;
									});
									marker.addListener('mouseout', function () {
										// infobox.close();
									});
								}
							} else {
								var pos = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
								if(!app.is_mobile) {
									infobox.setContent($(".info-windows .info-window")[0]);
									infobox.setPosition(pos);
									infobox.open(map, marker);
								} else {
									map.setCenter(pos);
								}
							}
						})(marker_data);
					}
				}
			}
		}
	};

	//app.initCallbacks.push(maps.init);
	return maps;

})(window.app, window.app.maps, google);
// USING MODULES APP, APP.MAPS & GOOGLE