
// INCLUDE MODULES
window.app = window.app || {};
window.app.resize = window.app.resize || {};

// MODULE EVENTS
window.app.resize = (function(app, resize, $) {

	resize.stack = [];

	resize.addFn = function(fn, args) {
		resize.stack.push({fn: fn, args: args});
	};

	resize.init = function() {
		$(window).resize(function() {
			window.app.resize.stack.forEach(function(el) {
				el.fn.apply(null, el.args);
			});
		});
	};

	app.initCallbacks.push(resize.init);
	return resize;

})(window.app, window.app.resize, jQuery);