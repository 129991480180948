
// INCLUDE MODULES
window.app = window.app || {};
window.app.home = window.app.home || {};

// MODULE EVENTS
window.app.home = (function(app, home, $) {

    home.init = function() {
        app.selectbox.bindOnSelected(
            '.home #header .js-selectbox',
            app.selectbox.goToOfficeOnSelected
        );
        if ($('.sluzby #header .js-selectbox').length) {
            app.selectbox.bindOnSelected(
                '.sluzby #header .js-selectbox',
                app.selectbox.goToOfficeOnSelected
            );
        }
    };

	app.initCallbacks.push(home.init);
	return home;

})(window.app, window.app.home, jQuery);