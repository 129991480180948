// MODULE quiz
window.app = (function(app, $) {
    var module = app.quiz = app.quiz || {};

    var showWinnersBtn = $('#showWinners');
    module.init = function() {

        //console.info('init app.quiz');

        $('.go-to').on('click', function(e){
            e.preventDefault();
            var link = $(this);
            module.scrollTo(link);
        });

    };

    module.scrollTo = function (el) {
        var headerHight = $('#menu-fixed').height() + 50;
        $('html, body').animate({
            scrollTop: $(el).offset().top - headerHight
        });
    };


    return app;

})(window.app || {}, jQuery);