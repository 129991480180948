
// INCLUDE MODULES
window.app = window.app || {};
window.app.search = window.app.search || {};

// MODULE SEARCH
window.app.search = (function(app, search, $) {

    search.$input = null;
	search.init = function() {
        search.$input = $("#menu .search-wrap .search-input");
		$("#menu .search-wrap").mouseenter(function() {
			if(app.is_desktop) {
				if($("#menu .search-wrap .search-input:visible").length == 0) {
					$("#menu .search-wrap .search-input").width(0).fadeIn(250).animate({width: 180}, 750);
				}
			}
		}).mouseleave(function() {
			if(app.is_desktop) {
				if($("#menu .search-wrap .search-input:focus").length == 0) {
					$("#menu .search-wrap .search-input").animate({width: 0}, 750).fadeOut(250);
				}
			}
		});
		search.$input.blur(function() {
			$("#menu .search-wrap").mouseleave();
		});

        $(document).on('click','#menu .search-wrap a',search.submit);

	};

    search.submit = function(e) {
        if ( search.$input.val() !="" ) {
            search.$input.closest('form').submit();
        }
        e.stopPropagation();
        return false;
    };

	app.initCallbacks.push(search.init);
	return search;

})(window.app, window.app.search, jQuery);