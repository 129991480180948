
// INCLUDE MODULES
window.app = window.app || {};
window.app.services = window.app.services || {};

// MODULE MENU PULLDOWN
window.app.services = (function(app, services, $) {

	services.scroll = function() {
		$(window).scroll(function() {
			if(app.is_desktop) {
				var bottom_scroll_top = app.components.bottom_scroll_top();
				var center_scroll_top = app.components.center_scroll_top();
				$(".services-snippet .service").each(function() {
					if(!$(this).hasClass("animated")) {
						if(
							( ( $(this).offset().top + ($(".services-snippet .services").outerHeight()/2) ) <= bottom_scroll_top) ||
							( $(this).offset().top <= center_scroll_top ) ) {
							$(this).addClass("animated");
							$(this).find(".img-wrap img").css({opacity: 0, transform: 'scaleX(1.5) scaleY(1.5)', visibility: "visible"}).delay(250).animate({opacity: 1, transform: 'scaleX(1) scaleY(1)'}, 750);
							$(this).find("h3").css({opacity: 0, top: 50, visibility: "visible"}).delay(250).animate({opacity: 1, top: 0}, 750);
							$(this).find("p").css({opacity: 0, top: 50, visibility: "visible"}).delay(250).animate({opacity: 1, top: 0}, 750);
						}
					} else { // REINIT ANIMATION
						if( ( $(this).offset().top ) >= bottom_scroll_top) {
							$(this).removeClass("animated");
							$(this).find(".img-wrap img").css({visibility: "hidden"});
							$(this).find("h3").css({visibility: "hidden"});
							$(this).find("p").css({visibility: "hidden"});
						}
					}
				});
			}
		});
	};

	services.snippet = function() {
		if(!app.is_desktop) {
			$(".services-snippet .service").each(function() {
				$(this).find(".img-wrap").css({visibility: "visible"});
				$(this).find("h3").css({visibility: "visible"});
				$(this).find("p").css({visibility: "visible"});
			});
		}
	};

	services.mobile = function() {
		$(".services-snippet .show-more .btn").click(function() {
			$(".services-snippet .service").slice(2).fadeIn(500);
			$contact_us_btn = $(".services-snippet .contact-us");
			if($contact_us_btn.length) $contact_us_btn.fadeIn(500);
			$(this).parents(".btn-holder").hide();
			return false;
		});
	};

	services.init = function() {
		services.scroll();
		services.snippet();
		services.mobile();
	};

	app.initCallbacks.push(services.init);
	return services;

})(window.app, window.app.services, jQuery);