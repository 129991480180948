
// INCLUDE MODULES
window.app = window.app || {};
window.app.checkbox = window.app.checkbox || {};

// MODULE SELECTBOX
window.app.checkbox = (function(app, checkbox, $) {

	checkbox.initializeCheckbox = function($checkbox) {
		if(!$checkbox.hasClass("js-initialized")) {
			$checkbox.wrap('<div class="js-checkbox"></div>');
			$checkbox.hide();
			var $cont = $checkbox.parents(".js-checkbox");
			$cont.append('<div class="checkbox"></div>');
			var $fake_checkbox = $cont.find(".checkbox");
			$fake_checkbox.click(function(e) {
				if(!$(this).hasClass("checked")) {
					$checkbox.prop('checked', false);
				} else {
					$checkbox.prop('checked', true);
				}
			});
			$checkbox.change(function() {
				if(!$fake_checkbox.hasClass("checked")) {
					$fake_checkbox.addClass("checked");
					$fake_checkbox.parents("label").addClass("checked");
				} else {
					$fake_checkbox.removeClass("checked");
					$fake_checkbox.parents("label").removeClass("checked");
				}
			});

			if($checkbox.prop('checked')) {
				$fake_checkbox.click();
			}

			$checkbox.addClass("js-initialized");
		}
	};

	checkbox.init = function() {
		$('[type="checkbox"]').each(function() {
			checkbox.initializeCheckbox($(this));
		});
	};

	app.initCallbacks.push(checkbox.init);
	return checkbox;

})(window.app, window.app.checkbox, jQuery);