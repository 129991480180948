
// INCLUDE MODULES
window.app = window.app || {};
window.app.rss = window.app.rss || {};

// MODULE SURVEY
window.app.rss = (function(app, rss, $, laravel) {


	rss.init = function() {
        $(document).on('click','#rss .pagination a',function(e){
            e.preventDefault();
            laravel.ajax.send({url:$(this).attr('href')});
        });
	};

	app.initCallbacks.push(rss.init);
	return rss;

})(window.app, window.app.rss, jQuery, laravel);