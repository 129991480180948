
// INCLUDE MODULES
window.app = window.app || {};
window.app.offices = window.app.offices || {};

// MODULE EVENTS
window.app.offices = (function(app, offices, $) {

    offices.init = function() {
        app.selectbox.bindOnSelected(
            '.infocentra #infocenter-point .js-selectbox',
            app.selectbox.goToOfficeOnSelected
        );
        offices.checkHeadlineHeight();
        offices.checkPersonCount();
    };

    offices.headers = $('.infocentra-detail .office-event-header');
    offices.persons = $('div.person .person-cont');

    offices.checkHeadlineHeight = function() {
          offices.headers.each(function() {
              if ($(this).html().length > 32) {
                  var position = 32;
                  var text = $(this).html();
                  while (position > 0) {
                      if (text[position] && (text[position] == '' || text[position] == ' ')) {
                          text = text.substring(0,position);
                          break;
                      } else {
                          position--;
                      }
                  }
                  $(this).html(text+'...')
              }
          })
    };

    offices.checkPersonCount = function() {
        var p_length = offices.persons.length;
        if (p_length != 3) {
            offices.persons.each(function() {
                $(this).addClass('count-' + p_length);
            });
        }
    };

	app.initCallbacks.push(offices.init);
	return offices;

})(window.app, window.app.offices, jQuery);