
// INCLUDE MODULES
window.app = window.app || {};
window.app.links = window.app.links || {};

// MODULE EVENTS
window.app.links = (function(app, links, $) {

	// lenost na N-tou :)
	links.included_sections = [
		"footer",
		"odkazy",
		"kontakty",
		"kontakty-infocentra",
		"ed-net"
	];

	links.checkLink = function($link) {
		if($link.attr("target")) return false;

		var href = $link.attr("href");
		var regs = [
			/tel:/i,
			/europe-direct/i
		];

		var ok = true;
		regs.forEach(function(reg) {
			if(reg.test(href)) {
				ok = false;
			}
		});

		if(!ok) return false;

		// link will be updated
		$link.attr("target", "_blank");
	};

	links.checkSection = function($section) {
		$section.find("a").each(function() {
			links.checkLink($(this));
		});
	};

	links.init = function() {
		links.included_sections.forEach(function(val) {
			$section = $("#"+val);
			links.checkSection($section);
		});
	};

	app.initCallbacks.push(links.init);
	return links;

})(window.app, window.app.links, jQuery);