
// INCLUDE MODULES
window.app = window.app || {};
window.app.counter = window.app.counter || {};

// MODULE COUNTER
window.app.counter = (function(app, counter, $) {

	counter.init = function() {
		$(".js-counter").each(function () {
			var num = $(this).text();
			if($(this).text().indexOf(",") >= 0) {
				num = num.replace(",", ".");
				$(this).attr("is-float", "true");
			}
			else {
				$(this).attr("is-float", "false");
			}
			$(this).attr("counter-end", num);
			$(this).text(0);
		});
	};

	app.initCallbacks.push(counter.init);
	return counter;

})(window.app, window.app.counter, jQuery);









