
// INCLUDE MODULES
window.app = window.app || {};
window.app.survey = window.app.survey || {};

// MODULE SURVEY
window.app.survey = (function(app, survey, $) {

    survey.choicesSelector = "#anketa .choices .choice";
	survey.init = function() {
        survey.bindEvents();
        survey.animateResults();
	};

    survey.bindEvents = function () {
        $(document).on('click',survey.choicesSelector,function(event) {
            event.preventDefault();
            laravel.ajax.send({
                url: '/survey/answer/'+ $(this).data('id')
            });
        });
    };

    survey.answered = function () {
        survey.animateResults();
    };

    survey.animateResults = function() {
        $(survey.choicesSelector).each(function() {
            //$(this).removeClass("js-can-hover");
            var percent = parseInt($(this).data("percent"));
            var from_offset = parseInt($(this).find(".outer").css("stroke-dashoffset"));

            var max_num = 303;
            var to_offset;

            if(percent < 70) {
                to_offset = (max_num/100)*(100-percent);
                to_offset = to_offset - (max_num/100*5);
                to_offset = to_offset + ((max_num/100*5)/100)*percent;
            } else if(percent == 100) {
                to_offset = 0;
            } else {
                to_offset = (max_num/100)*(100-(percent-7.5));
            }
            TweenMax.to($(this).find(".outer"), 2, {strokeDashoffset: to_offset, ease: Power2.easeInOut});

            $(this).find(".num").prop('Counter', 0).animate({
                Counter: $(this).data("num")
            }, {
                duration: 2000,
                easing: 'swing',
                step: function (now) {
                    now = Math.floor(now);
                    $(this).text(now);
                }
            });
        });
        return false;
    };

	app.initCallbacks.push(survey.init);
	return survey;

})(window.app, window.app.survey, jQuery);