
// INCLUDE MODULES
window.app = window.app || {};
window.app.events = window.app.events || {};

// MODULE EVENTS
window.app.events = (function(app, events, $) {

	events.bind_day_hover = function() {
		var leave_timeout;

		$("#events .swiper-day .day").mouseenter(function() {
			clearTimeout(leave_timeout);
			var $swiper_day = $(this).parents(".swiper-day");
			var $info_window = $swiper_day.find(".info-window");
			if($info_window.length) {
				var vp = app.components.viewport();
				var width = vp.width;
				var left_const = 8;
				if(width < 1600) left_const = 0;
				if(width < 1400) left_const = -8;

				var left_pos = $swiper_day.position().left
					+ (($("#events .section-content").outerWidth() - $(this).parents(".slide-center-inner").outerWidth()) / 2)
					+ left_const;

				$("#events .info-windows .info-window").remove();
				var clone = $info_window.clone(true);
				clone.css({left: left_pos}).hide().appendTo("#events .info-windows").fadeIn(500);

				var needed_width = clone.outerWidth() + clone.position().left;
				var max_width = $("#events .section-content").outerWidth();
				var width_diff = max_width - needed_width;
				if(width_diff < 0) {
					clone.css({left: left_pos + width_diff}).find(".zobak").css({left: "-="+width_diff});
				}

				//var fn = function(img) {
				//	var $img = $(img);
				//	var $thumb = $img.parents(".thumb");
				//	var max_height = $thumb.height();
				//	var max_width = $thumb.width();
				//	var height = $img.height();
				//
				//	console.log(max_height, height, max_width);
				//	if(max_height < height) {
				//		var diff_height = height - max_height;
				//		$img.css({marginTop: -(diff_height/2)+"px"});
				//		console.log("a");
				//	}
				//	else if(max_height > height) {
				//		var aspect = img.naturalWidth / img.naturalHeight;
				//		var new_width = aspect * max_height;
				//		var diff_width = new_width - max_width;
				//		if(diff_width > 0) {
				//			$img.css({height: max_height, width: new_width, marginLeft: -(diff_width/2)+"px"});
				//		}
				//		console.log("b");
				//	}
				//};
				//$info_window.find("li").each(function(){
				//	var img = $(this).find(".thumb img")[0];
				//	if(!img.complete) {
				//		$(img).load(function() {
				//			fn(this);
				//		});
				//	} else {
				//		fn(img);
				//	}
				//});
			}
		}).mouseleave(function() {
			leave_timeout = setTimeout(function() {
				$("#events .info-windows .info-window").fadeOut(500, function() {
					$(this).remove();
				});
			}, 500);
		});

		$("#events").on("mouseenter", ".info-windows .info-window", function() {
			clearTimeout(leave_timeout);
		}).on("mouseleave", ".info-windows .info-window", function() {
			leave_timeout = setTimeout(function() {
				$("#events .info-windows .info-window").fadeOut(500, function() {
					$(this).remove();
				});
			}, 500);
		});
	};

	events.bind_day_click = function() {
		$("#events .swiper-day .day").click(function(e) {
            e.preventDefault();
			$("#events .swiper-day .day").removeClass("hover");
			$(this).addClass("hover");
            laravel.ajax.send({
                url: '/akce/nacist-den/' + $(this).data('date')
            });
			//var $swiper_day = $(this).parents(".swiper-day");
			//if($swiper_day.data("event-id") && ($swiper_day.hasClass("has-events") || $swiper_day.hasClass("long-event"))) {
			//	$("#events .swiper-day .day").removeClass("active");
			//	$(this).addClass("active");
            //
			//	$("#events .events").height($("#events .event").height());
			//	setTimeout(function(){$("#events .events").css({height: "auto"});}, 400);
			//	$("#events .events .event").remove();
            //
			//	var ids = new String($swiper_day.data("event-id"));
			//	ids = ids.split(",");
			//	for(i in ids) {
			//		var id = ids[i];
			//		$("#event-"+id).clone(true).hide().appendTo("#events .events").fadeIn(750);
			//	}
			//}
			//return false;
		});

		$("#events .events").on("click", ".js-event", function() {
			window.location.href = "/aktuality/detail/"+$(this).data("event-id");
		});
	};

	events.bind_event_click = function() {
		$("#events .events").on("click", ".js-event", function() {
			window.location.href = "/aktuality/detail/"+$(this).data("event-id");
		});
	};

    events.showBoxes = function() {
        $("#loadNextEvents").hide();
        $("#showAllEvents").hide();
        $("#boxesEmptyMessage").hide();
		var fn = function(img){
			var $img = $(img);
			var height = img.clientHeight;
			var max_height = parseInt($img.parents(".header-img").height());
			var max_width = parseInt($img.parents(".header-img").width());

			if(max_height < height) {
				var diff_height = height - max_height;
				$img.css({marginTop: -(diff_height/2)+"px"});
			}
			else if(max_height > height) {
				var aspect = img.naturalWidth / img.naturalHeight;
				var new_width = aspect * max_height;
				var diff_width = new_width - max_width;
				if(diff_width > 0) {
					$img.css({height: max_height, width: new_width, marginLeft: -(diff_width/2)+"px"});
				}
			}
		};
        TweenMax.staggerFromTo(
            $("#events .events .event.hasToBeShown"),
            .5,
            {css:{display: "block", opacity: 0, position: "relative", top: 50}},
            {css:{display: "block", opacity: 1, top: 0}, onStart: function(){
                $(this.target).removeClass('hasToBeShown');

				// CENTER IMAGE
				var $img = $(this.target).find(".header-img img");
				var img = $img[0];
				if(!img.complete) {
					$(img).load(function() {
						fn(this);
					});
				} else {
					fn(img);
				}
				app.resize.addFn(fn, [img]);
            }}
            , .25);
    };

	events.scrollToBoxes = function() {
		if(app.is_mobile) {
			$("html, body").animate({scrollTop: $("#events .filter").offset().top + $("#events .filter").outerHeight() - $("#menu-fixed").outerHeight()}, 500);
		}
	};

    events.showButtonAll = function() {
        $("#showAllEvents").show();
    };

    events.showButtonMore = function() {
        $("#loadNextEvents").show();
    };
    events.showEmptyMessage = function() {
        $("#boxesEmptyMessage").show();
    };

	events.filter_selectbox = function() {
		var officeSelected = function(e) {
			var $option = $(e.target);
			var type = $option.data("type");
			$("#events .filter .js-select-hidden").val(type);
		};
		app.selectbox.bindOnSelected("#events .filter .js-selectbox", officeSelected);
		app.checkbox.initializeCheckbox($("#events .filter #form-only_archive"));
	};

    events.init = function() {
		events.bind_day_hover();
		events.bind_day_click();
		events.bind_event_click();
        events.showBoxes();
		events.filter_selectbox();
	};

    events.pushState = function(url) {
        //document.getElementById("content").innerHTML = response.html;
        window.history.pushState({}, document.title, url);
    };

	app.initCallbacks.push(events.init);
	return events;

})(window.app, window.app.events, jQuery);