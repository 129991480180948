// MODULE eurotime
window.app = (function(app, $) {
    var module = app.eurotime = app.eurotime || {};

    var showWinnersBtn = $('#showWinners');
    module.init = function() {

        //console.info('init app.eurotime');

        app.selectbox.bindOnSelected('#selectForm .js-selectbox', selectboxClicked);
        app.selectbox.bindOnSelected('#selectWinnersForm .js-selectbox', selectboxClicked);

        showWinnersClicked();

        $('.go-to').on('click', function(e){
            e.preventDefault();
            var link = $(this);
            module.scrollTo(link);
        });

    };

    module.scrollTo = function (el) {
        var headerHight = $('#menu-fixed').height() + 50;
        $('html, body').animate({
            scrollTop: $(el).offset().top - headerHight
        });
    };

    var selectboxClicked = function (e) {
        //e.preventDefault();
        $target = $(e.target);
        $('#'+$target.data('input')).val($target.data('type'));
    };

    var showWinnersClicked = function (e) {
        showWinnersBtn.click(function(e) {
            var officeId = $('#form-office_id').val();
            if (officeId) {
                window.history.pushState('Eurotime', 'Europe Direct 1', '/eurotime/' + $('#eurotimeYear').val() + '?office_id=' + officeId);
            }
        });
    };

    return app;

})(window.app || {}, jQuery);