
// INCLUDE MODULES
window.app = window.app || {};
window.app.swipers = window.app.swipers || {};

// MODULE SWIPERS
window.app.swipers = (function(app, swipers, $) {

	swipers.swiperHeaderInit = function() {
		var swajpa_header;
		if(!$("#header .swiper-container").hasClass("no-swiper")) {
			swajpa_header = $("#header .swiper-container").swiper({
				pagination: ".swiper-navigation-cont",
				paginationClickable: true,
				autoplay: 8000,
				speed: 555,
				loop: true,
				grabCursor: true,
				onInit: function(){
					$("#header .swiper-container").addClass('swiper-init');
                    if ($('.swiper-slide-active').hasClass('hide-infocentrum-choose')){
                        $('#header .selectbox.js-selectbox').fadeOut(200);
                    }
                    else {
                        $('#header .selectbox.js-selectbox').fadeIn(200);
                    }
				},
                onSlideChangeStart: function(){
                    if ($('.swiper-slide-active').hasClass('hide-infocentrum-choose')){
                        $('#header .selectbox.js-selectbox').fadeOut(200);
                    }
                    else {
                        $('#header .selectbox.js-selectbox').fadeIn(200);
                    }
                }
			});
			$("#header .arr.left").click(function() {
				swajpa_header.swipePrev();
				return false;
			});
			$("#header .arr.right").click(function() {
				swajpa_header.swipeNext();
				return false;
			});
		}


		return swajpa_header;
	};

	swipers.swiperNabidkaDocumentu = function() {
		var swajpa_page;
		if(!$(".info-carousel .swiper-container").hasClass("no-swiper")) {
			swajpa_page = $(".info-carousel .swiper-container").swiper({
				pagination: ".swiper-navigation-cont",
				paginationClickable: true,
				autoplay: 8000,
				speed: 555,
				loop: true,
				grabCursor: true
			});
			$(".info-carousel .arr.left").click(function() {
				swajpa_page.swipePrev();
				return false;
			});
			$(".info-carousel .arr.right").click(function() {
				swajpa_page.swipeNext();
				return false;
			});
		}
		return swajpa_page;
	};

	swipers.swiperEventsInit = function() {
		var swajpa_events;
		if($("#events .swiper-container").length) {
			swajpa_events = $("#events .swiper-container").swiper({
				speed: 555,
				onSlideChangeEnd: function(swiper) {
					$("#events .swiper-outer-container h3").text($(swiper.activeSlide()).data("month"));
				},
				onInit: function(swiper) {
					$(swiper.activeSlide()).find(".swiper-day.has-events:first").addClass("hover").find(".day").addClass("hover");
				}
			});

			setTimeout(function(){
				$("#events .swiper-outer-container").addClass('swiper-init');
			}, 500);
			// Na tomhle swiperu nefunguje onInit. Nevim proc. QuickBadFix

			$("#events .arr.left").click(function() {
				swajpa_events.swipePrev();
				return false;
			});
			$("#events .arr.right").click(function() {
				swajpa_events.swipeNext();
				return false;
			});
			$("#events .swiper-container .swiper-day.has-events, #events .swiper-container .swiper-day.long-event").mouseenter(function() {
				$("#events .swiper-container .swiper-day.hover").removeClass("hover").find(".day").removeClass("hover");
			});
            //Swajpnu na aktuální týden...
            swajpa_events.swipeTo(3, 0, false);
		}
		return swajpa_events;
	};

	swipers.swiperNumbersInit = function() {
		var swajpa_numbers;

		if($("#numbers .swiper-container").length) {
			var viewed_slides;

			if(!app.is_mobile) {
				viewed_slides = 3;
			} else {
				viewed_slides = 1;
			}

			swajpa_numbers = $("#numbers .swiper-container").swiper({
				speed: 555,
				loop: true,
				slidesPerView: viewed_slides,
				slidesPerGroup: viewed_slides,
				onlyExternal: true,
				onSlideChangeEnd: function(swiper) {
					$(swiper.slides).find(".js-counter").each(function() {
						$(this).stop(true, true).text(0);
					});

					$slides = $(swiper.activeSlide());
					if(viewed_slides == 3) {
						$slides.push($(swiper.activeSlide()).next(".swiper-slide")[0]);
						$slides.push($(swiper.activeSlide()).next(".swiper-slide").next(".swiper-slide")[0]);
					}

					$slides.find(".js-counter").each(function() {
						$(this).prop('Counter', 0).animate({
							Counter: $(this).attr("counter-end")
						}, {
							duration: 3000,
							easing: 'swing',
							step: function (now) {
								if($(this).attr("is-float") == "false") {
									now = new String(Math.ceil(this.Counter));
									if(Number(now) > 999){
										while (/(\d+)(\d{3})/.test(now)) {
											now = now.replace(/(\d+)(\d{3})/, '$1' + ' ' + '$2');
										}
									}
								}
								else {
									now = new String(Math.ceil(now * 100) / 100).replace(".", ",");
								}
								$(this).text(now);
							}
						});
					});
				}
			});
			$("#numbers .arr.left").click(function() {
				swajpa_numbers.swipePrev();
				return false;
			});
			$("#numbers .arr.right").click(function() {
				swajpa_numbers.swipeNext();
				return false;
			});
		}
		return swajpa_numbers;
	};

	swipers.swiperServicesInit = function() {
		var swajpa_services;
		if (app.is_desktop && $(".services-snippet .swiper-container").length) {

			var num_cols = 4;
			if($(".services-snippet .swiper-container .swiper-slide").length <= num_cols) {
				num_cols = $(".services-snippet .swiper-container .swiper-slide").length;
				$(".services-snippet .arr").hide();
			}

			swajpa_services = $(".services-snippet .swiper-container").swiper({
				speed: 555,
				grabCursor: true,
				slidesPerView: num_cols,
				slidesPerGroup: 1,
				calculateHeight: true
			});
			$(".services-snippet .arr.left").click(function () {
				swajpa_services.swipePrev();
				return false;
			});
			$(".services-snippet .arr.right").click(function () {
				swajpa_services.swipeNext();
				return false;
			});
		}
		else {
			$(".services-snippet .arr").hide();
		}
		return swajpa_services;
	};

	swipers.scroll = function() {
		$(window).scroll(function() {
			if(swipers.swiper_numbers) {
				var bottom_scroll_top = app.components.bottom_scroll_top();
				var $numbers = $("#numbers");
				var $numbers_cont = $("#numbers .swiper-outer-container");
				if(!$numbers.hasClass("animated")) {
					if( ( $numbers_cont.offset().top + ($numbers_cont.outerHeight() / 2) ) <= bottom_scroll_top) {
						$numbers.addClass("animated");
						swipers.swiper_numbers.fireCallback('SlideChangeEnd', swipers.swiper_numbers);
					}
				} else {
					if( ( $numbers_cont.offset().top ) >= bottom_scroll_top) {
						$numbers.removeClass("animated");
						$numbers_cont.find(".js-counter").text(0);
					}
				}
			}
		});
	};

	swipers.init = function() {
		swipers.swiper_header = swipers.swiperHeaderInit();
		swipers.swiper_events = swipers.swiperEventsInit();
		swipers.swiper_numbers = swipers.swiperNumbersInit();
		swipers.swiper_services = swipers.swiperServicesInit();
		swipers.swiper_nabidka = swipers.swiperNabidkaDocumentu();
		swipers.scroll();
		setTimeout(function(){
			$(".swiper-container").addClass('swiper-init');
			$(".swiper-outer-container").addClass('swiper-init');
		}, 500);
	};

	app.initCallbacks.push(swipers.init);
	return swipers;

})(window.app, window.app.swipers, jQuery);
// USING MODULES APP, APP.SWIPERS & JQUERY