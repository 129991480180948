
// INCLUDE MODULES
window.app = window.app || {};
window.app.tabs = window.app.tabs || {};

// MODULE tabs
window.app.tabs = (function(app, tabs, $) {

	tabs.init = function() {
		$('.tabs').each(function(){
			var tabsWrap = $(this),
				control = tabsWrap.find('.tabs__control'),
				tabs = tabsWrap.find('.tabs__tab');

			tabs.filter(':gt(0)').addClass('is-hidden');
			control.find('li').eq(0).addClass('is-active');

			control.find('a').on('click', function(e){
				e.preventDefault();

				var link = $(this);

				window.app.tabs.change(tabsWrap, link);
			});
		});
	};

	tabs.change = function(wrap, link) {
		wrap.find('.tabs__control .is-active').removeClass('is-active');
		wrap.find('.tabs__tab').addClass('is-hidden');

		link.parent().addClass('is-active');
		$(link.attr('href')).removeClass('is-hidden');
	};

	app.initCallbacks.push(tabs.init);
	return tabs;

})(window.app, window.app.tabs, jQuery);