
// INCLUDE MODULES
window.app = window.app || {};
window.app.arr_up = window.app.arr_up || {};

// MODULE MENU PULLDOWN
window.app.arr_up = (function(app, arr_up, $) {

	arr_up.click = function() {
		$("#arr-up").click(function() {
			$("html, body").animate({scrollTop: 0}, 1000);
			return false;
		});
	};

	arr_up.scroll = function() {
		$(window).scroll(function() {
			var scroll_top = app.components.scroll_top();
			var bottom_scroll_top = app.components.bottom_scroll_top();

			var $arr_up = $("#arr-up");
			if(scroll_top < 100) {
				$arr_up.css({bottom: -60});
			} else {
				$arr_up.css({bottom: 0});
			}

			if($("#sub-footer").offset().top <= bottom_scroll_top) {
				$arr_up.addClass("docked");
				$arr_up.css({bottom: $("#sub-footer").height()});
			} else {
				$arr_up.removeClass("docked");
				$arr_up.css({bottom: 0});
			}
		});
	};

	arr_up.init = function() {
		arr_up.click();
		arr_up.scroll();
	};

	app.initCallbacks.push(arr_up.init);
	return arr_up;

})(window.app, window.app.arr_up, jQuery);