
// INCLUDE MODULES
window.app = window.app || {};
window.app.accordeon = window.app.accordeon || {};

// MODULE ACCORDEON
window.app.accordeon = (function(app, accordeon, $) {

	accordeon.init = function() {
		$(".js-accordeon .js-accordeon-item").click(function(e) {

			var accordeon_class = $(this).parents(".js-accordeon").eq(0).data("accordeon-class");
			if(accordeon_class) accordeon_class = "."+accordeon_class;
			else accordeon_class = "";

			if($(e.target).hasClass(".js-accordeon-content"+accordeon_class) || $(e.target).parents(".js-accordeon-content"+accordeon_class).length) return;

			if($(this).hasClass("active")) {
				var _this = this;
				$(this).find(".js-accordeon-content"+accordeon_class).slideUp(500, function() {
					$(_this).removeClass("active");
				});
			} else {
				$(this).addClass("active");
				$(this).find(".js-accordeon-content"+accordeon_class).slideDown(500);
			}

			return false;
		});
	};

	app.initCallbacks.push(accordeon.init);
	return accordeon;

})(window.app, window.app.accordeon, jQuery);