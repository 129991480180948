
// INCLUDE MODULES
window.app = window.app || {};
window.app.newsletter = window.app.newsletter || {};

// MODULE EVENTS
window.app.newsletter = (function(app, newsletter, $) {

	newsletter.url = '';

	newsletter.infocenter_selectbox = function() {
		if ($("#newsletterUrl").length) {
			newsletter.url = $("#newsletterUrl").val();
		}
		app.selectbox.bindOnSelected("#newsletter .js-selectbox", officeSelected );
		app.selectbox.bindOnSelected("#newsletter .newsletter-select-box", newsletterOfficeSelected );
		submitNewsletterOffice();
	};

    var officeSelected = function(e) {
        var $option = $(e.target);
        var type = $option.data("type");
		$("#newsletterUrl").val(newsletter.url+"/"+type);
        $("#newsletter .js-select-hidden").val(type);
    };

	var newsletterOfficeSelected = function(e) {
		var $option = $(e.target);
		var type = $option.data("type");
		$("#newsletter #selectedNewsOffice").val(type);
	};

	var submitNewsletterOffice = function(e) {
		if ($('#submitOffice').length) {
			$('#submitOffice').click(function(e) {
				if ($("#newsletter #selectedNewsOffice").val() == 0) {
					e.preventDefault();
				} else {
					window.location = $("#newsletterUrl").val();
				}
			});
		}
	};

	newsletter.init = function() {
		newsletter.infocenter_selectbox();
	};

	app.initCallbacks.push(newsletter.init);
	return newsletter;

})(window.app, window.app.newsletter, jQuery);