
// INCLUDE MODULES
window.app = window.app || {};
window.app.selectbox = window.app.selectbox || {};

// MODULE SELECTBOX
window.app.selectbox = (function(app, selectbox, $) {

	selectbox.init = function() {
		$(document).on('click', ".js-selectbox .selectbox-btn", function() {
			$selectbox = $(this).parents(".js-selectbox");
			selectboxType = $selectbox.parents(".section").attr("id");
			if($selectbox.find(".selectbox-list:visible").length) {
				$selectbox.removeClass("active");
				$(this).removeClass("active");
				$selectbox.find(".selectbox-list").slideUp(500);
				selectbox.dieBlur(selectboxType);
			} else {
				$selectbox.addClass("active");
				$(this).addClass("active");
				$selectbox.find(".selectbox-list").slideDown(500);
				selectbox.blur($selectbox, selectboxType);
			}
			return false;
		});
		$(document).on('click',".js-selectbox .selectbox-list a", function() {
			$selectbox = $(this).parents(".js-selectbox");
			$selectbox.removeClass("active");
			$selectbox.find(".selectbox-list a").removeClass("active");
			if($(this).hasClass("js-selectbox-clear")) {
				$selectbox.find(".selectbox-btn").text($selectbox.find(".selectbox-btn").data("default-text"));
				$selectbox.removeClass("selected");
			} else {
				$(this).addClass("active");
				$selectbox.find(".selectbox-btn").text($(this).text());
				$selectbox.addClass("selected");
			}
			$selectbox.find(".selectbox-btn").click();
			return false;
		});

    };

	selectbox.blur = function($selectbox, selectboxType) {
		$(document).one("click.selectbox_blur_"+selectboxType, function(e) {
			$target = $(e.target);
			if(!$target.hasClass("js-selectbox") && $target.parents(".js-selectbox").length <= 0) {
				$selectbox.find(".selectbox-btn").removeClass("active");
				$selectbox.find(".selectbox-list").slideUp(500);
			}
		});
	};

	selectbox.dieBlur = function(selectboxType) {
		$(document).off("click.selectbox_blur_"+selectboxType);
	};

    selectbox.bindOnSelected = function(parentSelector, eventHandler) {
        $(document).on('click', parentSelector + ' .selectbox-list a', eventHandler || selectbox.defaultSelectedHandler);
    };

    selectbox.defaultSelectedHandler = function(e) {
        window.location.href = $(e.target).attr("href");
    };

    selectbox.goToOfficeOnSelected = function(e) {
        selectbox.defaultSelectedHandler(e);
    };

	app.initCallbacks.push(selectbox.init);
	return selectbox;

})(window.app, window.app.selectbox, jQuery);