
// INCLUDE MODULES
window.app = window.app || {};
window.app.menu = window.app.menu || {};

// MODULE MENU PULLDOWN
window.app.menu = (function(app, menu, $) {

	menu.pulldown = function() {
		var top_animation = 30;
		$("#menu .item").on("mouseenter", function() {
			var pulldown = $(this).find(".pulldown .inner");
			if(pulldown) {
				TweenMax.fromTo(pulldown, .5, {css: {opacity: 0, marginTop: top_animation, display: "block"}}, {css: {opacity: 1, marginTop: 0, display: "block"}});
			}
		}).on("mouseleave", function() {
			var pulldown = $(this).find(".pulldown .inner");
			if(pulldown) {
				TweenMax.fromTo(pulldown, .5, {css: {opacity: 1, marginTop: 0, display: "block"}}, {css: {opacity: 0, marginTop: top_animation, display: "none"}});
			}
		});
	};

	menu.mobile = function() {
		$("#menu .mobile-menu").click(function() {
			if($("#menu-fixed").hasClass("open")) {
				$("#menu-fixed").removeClass("open");
			} else {
				$("#menu-fixed").addClass("open");
			}
			return false;
		});
	};

	menu.scroll = function() {
		$(window).scroll(function() {
			var scroll_top = app.components.scroll_top();
			var $super_menu = $("#super-menu");
			var $menu = $("#menu");
			if(scroll_top >= $super_menu.outerHeight()) {
				$menu.addClass("fixed");
			} else {
				$menu.removeClass("fixed");
			}
			if(scroll_top >= ($super_menu.outerHeight() + ($menu.outerHeight() / 2))) {
				$menu.addClass("small");
			} else {
				$menu.removeClass("small");
			}
		});
	};

	menu.init = function() {
		menu.pulldown();
		menu.mobile();
		menu.scroll();
	};

	app.initCallbacks.push(menu.init);
	return menu;

})(window.app, window.app.menu, jQuery);